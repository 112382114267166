import { render, staticRenderFns } from "./CVEModal.vue?vue&type=template&id=71e1d96e&scoped=true"
import script from "../scripts/CVEModal.js?vue&type=script&lang=js&external"
export * from "../scripts/CVEModal.js?vue&type=script&lang=js&external"
import style0 from "../styles/Modal.scss?vue&type=style&index=0&id=71e1d96e&prod&scoped=true&lang=scss&external"
import style1 from "../styles/CVEModal.scss?vue&type=style&index=1&id=71e1d96e&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71e1d96e",
  null
  
)

export default component.exports